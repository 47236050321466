import React, {
    ChangeEvent,
    Dispatch,
    FC,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import { FormikErrors, FormikTouched } from "formik";
import { IParsedRawIngredient } from "pages/rawIngredient/types";
import BigNumber from "bignumber.js";
import { rawIngredientService } from "services";
import { IParsedFormula } from "pages/formulas/formulas/types";
import classNames from "classnames";
import {
    ContaminantsSection,
    Header,
    RawIngredientInputs,
    SubIngredientsSection,
} from "./components";
import { useFormulaStore } from "pages/formulas/formulas/store";
// import { useRawIngredient } from "pages/formulas/formulas/queries";

interface IProps {
    index: number;
    errors: FormikErrors<IParsedFormula>;
    isError: Record<string, boolean>;
    setIsError: Dispatch<SetStateAction<Record<string, boolean>>>;
    touched: FormikTouched<IParsedFormula>;
    item: IParsedRawIngredient;
    values: IParsedFormula;
    handleChange: (e: ChangeEvent<unknown>) => void;
    removeRawIngredient: <X = unknown>(index: number) => X | undefined;
    setFieldValue: (
        field: string,
        value: unknown,
        shouldValidate?: boolean | undefined,
    ) => Promise<void> | Promise<FormikErrors<IParsedFormula>>;
}

export const RawIngredientItem: FC<IProps> = ({
    index,
    errors,
    isError,
    setIsError,
    touched,
    values,
    item,
    handleChange,
    removeRawIngredient,
    setFieldValue,
}) => {
    const [isOpenSubDialog, setIsOpenSubDialog] = useState(false);
    const [isOpenContaminantDialog, setIsOpenContaminantDialog] =
        useState(false);
    const itemErrors = errors?.rawIngredients?.[index];
    const itemTouched = touched.rawIngredients?.[index] || {};
    const itemValues = values.rawIngredients?.[index];

    const handleToggleOpenSubDialog = () => {
        setIsOpenSubDialog(!isOpenSubDialog);
    };

    const handleToggleOpenContaminantDialog = () => {
        setIsOpenContaminantDialog(!isOpenContaminantDialog);
    };

    const totalSubWeight = useMemo(() => {
        return item?.subIngredients?.reduce((acc, ingredient) => {
            return new BigNumber(acc)
                .plus(ingredient?.weight_percent)
                .toNumber();
        }, 0);
    }, [item]);

    useEffect(() => {
        if (itemValues?.description) {
            const body = {
                ricode: itemValues?.ricode,
                description: itemValues?.description,
            };
            rawIngredientService
                .checkIsUserOwner(body)
                .then((res) => {
                    setFieldValue(
                        `rawIngredients.${index}.isOwner`,
                        res?.isOwner,
                    );
                    setFieldValue(
                        `rawIngredients.${index}.isExist`,
                        res?.isExist,
                    );
                })
                .catch(() => {
                    setFieldValue(`rawIngredients.${index}.isOwner`, false);
                    setFieldValue(`rawIngredients.${index}.isExist`, false);
                });
        }
    }, [itemValues?.ricode, itemValues?.description]);

    const canModifyInner = useMemo(() => {
        return true;
        return (
            (itemValues.isExist && itemValues.isOwner) || !itemValues.isExist
        );
    }, [itemValues]);

    const {
        formula,
        removeRawIngredient: removeIngredient,
        updateRawIngredient,
    } = useFormulaStore();

    useEffect(() => {
        if (itemValues?.description) {
            updateRawIngredient(index, itemValues);
        }
    }, [index, JSON.stringify(itemValues)]);

    // const { data: existingRawIngredient } = useRawIngredient(
    //     itemValues?.description,
    // );

    useEffect(() => {
        setIsError((state) => ({
            ...state,
            [index]: !(
                !Boolean(errors?.rawIngredients?.[index])
                // &&
                // (formula?.rawIngredients?.[index]?.ricode ||
                //     !existingRawIngredient?.rows?.length)
            ),
        }));
    }, [errors?.rawIngredients?.[index], formula?.rawIngredients?.[index]]);

    const handleRemoveIngredient = useCallback(() => {
        removeRawIngredient(index);
        removeIngredient(index);
    }, [index]);

    return (
        <div
            className={classNames("p-4 rounded-lg border shadow-lg mb-2", {
                "shadow-red-700": isError[index],
            })}
        >
            <Header index={index} onRemove={handleRemoveIngredient} />
            <RawIngredientInputs
                index={index}
                itemErrors={itemErrors as FormikErrors<IParsedRawIngredient>}
                itemTouched={itemTouched}
                itemValues={itemValues}
                handleChange={handleChange}
            />

            {canModifyInner ? (
                <>
                    <SubIngredientsSection
                        index={index}
                        item={item}
                        itemErrors={itemErrors}
                        setIsError={setIsError}
                        itemTouched={itemTouched}
                        itemValues={itemValues}
                        handleChange={handleChange}
                        totalSubWeight={totalSubWeight}
                        isOpenSubDialog={isOpenSubDialog}
                        handleToggleOpenSubDialog={handleToggleOpenSubDialog}
                    />
                    <ContaminantsSection
                        index={index}
                        item={item}
                        itemErrors={itemErrors}
                        itemTouched={itemTouched}
                        itemValues={itemValues}
                        handleChange={handleChange}
                        isOpenContaminantDialog={isOpenContaminantDialog}
                        handleToggleOpenContaminantDialog={
                            handleToggleOpenContaminantDialog
                        }
                    />
                </>
            ) : (
                <h6 className="py-2 text-[#6a778a] text-base">
                    The requested Raw Ingredient or data belongs to Vendor -
                    Modification unavailable
                </h6>
            )}
        </div>
    );
};
