import React from "react";
import { useSubIngredientData } from "pages/subIngredient/subIngredientProfile/hooks";

export const SourceLink = () => {
    const { ingredientSource } = useSubIngredientData();
    return (
        <div className="mt-4">
            <a
                href={ingredientSource.url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 underline hover:text-blue-700 transition-colors duration-200"
            >
                <span>INCI Source: </span>
                {ingredientSource.text}
            </a>
        </div>
    );
};
