import React, { ChangeEvent, FC, useState } from "react";
import { IBasicData } from "pages/subIngredient/subIngredientProfile/types";
import { TableRow } from "./TableRow";

const organizationOptions = [
    { label: "Leave On", value: "leave_on" },
    { label: "Rinse Off", value: "rinse_off" },
];

interface IProps {
    data: IBasicData;
    onChangeData: (
        e: ChangeEvent<
            HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
        >,
    ) => void;
}

export const RightTable: FC<IProps> = ({ data, onChangeData }) => {
    const [isCIRReviewed, setIsCIRReviewed] = useState(
        data.cir_reviewed === "Yes",
    );

    const handleCIRReviewedChange = (e: ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked;
        setIsCIRReviewed(checked);
        onChangeData({
            target: {
                name: "cir_reviewed",
                value: checked ? "Yes" : "No",
            },
        } as ChangeEvent<HTMLInputElement>);
    };

    return (
        <div className="w-1/2 md:w-1/2 lg:w-1/2 md:ml-5">
            <table className="w-full bg-grey-100 rounded-lg overflow-hidden sm:shadow-lg mb-2">
                <tbody>
                    <TableRow
                        header="CIR Reviewed"
                        type="cir_reviewed"
                        value={data.cir_reviewed}
                        content={data.cir_citation}
                        onChange={onChangeData}
                        onChangeCIRReviewed={handleCIRReviewedChange}
                        isCIRReviewed={isCIRReviewed}
                    />
                    <TableRow
                        header="CIR Intended Use"
                        name="cir_lo"
                        value={data?.cir_lo}
                        onChange={onChangeData}
                        options={organizationOptions}
                        type="dropdown"
                        defaultLabel="Not specified"
                        defaultValue=""
                        defaultOption
                    />
                    <TableRow
                        header="IECIC Intended Use"
                        name="iecic_lo"
                        value={data?.iecic_lo}
                        onChange={onChangeData}
                        options={organizationOptions}
                        type="dropdown"
                        defaultLabel="Not specified"
                        defaultValue=""
                        defaultOption
                    />
                    <TableRow
                        header="UV Filter"
                        name="uv_filter"
                        onChange={onChangeData}
                        value={data.uv_filter}
                        comment={data?.uv_filter_comment}
                        type="radio"
                    />
                </tbody>
            </table>
        </div>
    );
};
