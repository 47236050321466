import React, { FC, useCallback } from "react";
import { FormikErrors, FormikTouched } from "formik";
import { InputWrapper } from "components/shared/inputWrapper";
import {
    IParsedRawIngredient,
    IParsedSubIngredient,
} from "pages/rawIngredient/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

interface IProps {
    index: number;
    prefix: string;
    errors?: FormikErrors<IParsedRawIngredient>;
    touched: FormikTouched<IParsedRawIngredient>;
    values: IParsedRawIngredient;
    handleChange: (e: React.ChangeEvent<unknown>) => void;
    remove: <X = unknown>(index: number) => X | undefined;
}

export const SubIngredientItem: FC<IProps> = ({
    index,
    prefix,
    errors,
    touched,
    values,
    handleChange,
    remove,
}) => {
    const itemErrors = errors?.subIngredients?.[index] as
        | FormikErrors<IParsedSubIngredient>
        | undefined;
    const itemTouched = touched.subIngredients?.[index] || {};
    const itemValues = values.subIngredients?.[index];

    const handleRemove = useCallback(() => {
        remove(index);
    }, [index]);

    return (
        <div className="mb-6 border rounded-lg p-4 shadow-md bg-white relative">
            <button
                onClick={handleRemove}
                className="absolute top-2 right-2 h-8 w-8 bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-700 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                type="button"
                title="Remove Sub-Ingredient"
            >
                <FontAwesomeIcon icon={faTimes} size="lg" />
            </button>

            <div className="flex space-x-4 mb-3">
                <InputWrapper
                    isError={
                        Boolean(itemErrors?.weight_percent) &&
                        itemTouched?.weight_percent
                    }
                    error={itemErrors?.weight_percent}
                    label="Weight %"
                    className="!w-1/3"
                >
                    <input
                        type="number"
                        name={`${prefix}.subIngredients.${index}.weight_percent`}
                        className={`w-full input input-bordered ${
                            itemErrors?.weight_percent &&
                            itemTouched?.weight_percent
                                ? "border-red-500"
                                : ""
                        } transition duration-200`}
                        value={itemValues.weight_percent}
                        onChange={handleChange}
                        placeholder="Weight"
                        required
                    />
                </InputWrapper>

                <InputWrapper
                    isError={Boolean(itemErrors?.name) && itemTouched?.name}
                    error={itemErrors?.name}
                    label="Name"
                    className="!w-1/3"
                >
                    <input
                        type="text"
                        name={`${prefix}.subIngredients.${index}.name`}
                        className={`w-full input input-bordered ${
                            itemErrors?.name && itemTouched?.name
                                ? "border-red-500"
                                : ""
                        } transition duration-200`}
                        value={itemValues.name}
                        onChange={handleChange}
                        placeholder="INCI Name"
                        required
                    />
                </InputWrapper>
            </div>

            <div className="flex space-x-4 mb-3">
                <InputWrapper
                    isError={
                        Boolean(itemErrors?.cas_number) &&
                        itemTouched?.cas_number
                    }
                    error={itemErrors?.cas_number}
                    label="CAS Number"
                    className="!w-1/3"
                >
                    <input
                        type="text"
                        name={`${prefix}.subIngredients.${index}.cas_number`}
                        className={`w-full input input-bordered ${
                            itemErrors?.cas_number && itemTouched?.cas_number
                                ? "border-red-500"
                                : ""
                        } transition duration-200`}
                        value={itemValues.cas_number}
                        onChange={handleChange}
                        placeholder="CAS Number"
                    />
                </InputWrapper>

                <InputWrapper
                    isError={
                        Boolean(itemErrors?.function) && itemTouched?.function
                    }
                    error={itemErrors?.function}
                    label="Function"
                    className="!w-1/3"
                >
                    <input
                        type="text"
                        name={`${prefix}.subIngredients.${index}.function`}
                        className={`w-full input input-bordered ${
                            itemErrors?.function && itemTouched?.function
                                ? "border-red-500"
                                : ""
                        } transition duration-200`}
                        value={itemValues.function}
                        onChange={handleChange}
                        placeholder="Function"
                    />
                </InputWrapper>
            </div>
        </div>
    );
};
