import { useState, useEffect, useMemo } from "react";
import { useGetSubIngredient } from "../queries";
import { IBasicData } from "../types";
import { InciSource } from "common/data";

export const useSubIngredientData = () => {
    const subIngredient = useGetSubIngredient();
    const [data, setData] = useState<IBasicData>({
        environmental_class: subIngredient?.environmental_class || "",
        skin_sensitizer: subIngredient?.skin_sensitizer?.toLowerCase() || "",
        food_use: subIngredient?.food_use?.toLowerCase() || "",
        function: subIngredient?.function || "",
        cir_citation: subIngredient?.cir_citation || "",
        cir_ro: subIngredient?.cir_ro || "",
        cir_lo: subIngredient?.cir_lo || "",
        cir_reviewed: subIngredient?.cir_reviewed || "",
        preservatives: subIngredient?.preservatives || "",
        uv_filter: subIngredient?.uv_filter || "",
        colorant: subIngredient?.colorant?.toLowerCase() || "",
        iecic_lo: subIngredient?.iecic_lo || "",
        iecic_ro: subIngredient?.iecic_ro || "",
        gluten: subIngredient?.gluten?.toLowerCase() || "",
        cramer_class: subIngredient?.cramer_class || "",
        cas_number: subIngredient?.cas_number || "",
        unique_smiles: subIngredient?.unique_smiles || "",
        inchi: subIngredient?.inchi || "",
        skin_sensitizer_comment: subIngredient?.skin_sensitizer_comment || "",
        food_use_comment: subIngredient?.food_use_comment || "",
        gluten_comment: subIngredient?.gluten_comment || "",
        colorant_comment: subIngredient?.colorant_comment || "",
        uv_filter_comment: subIngredient?.uv_filter_comment || "",
        source: subIngredient?.source || "",
    });

    useEffect(() => {
        if (subIngredient) {
            setData({
                ...data,
                environmental_class: subIngredient.environmental_class || "",
                skin_sensitizer:
                    subIngredient.skin_sensitizer?.toLowerCase() || "",
                food_use: subIngredient.food_use?.toLowerCase() || "",
                function: subIngredient.function || "",
                cir_citation: subIngredient.cir_citation || "",
                cir_ro: subIngredient.cir_ro || "",
                cir_lo: subIngredient.cir_lo || "",
                cir_reviewed: subIngredient.cir_reviewed || "",
                preservatives: subIngredient.preservatives || "",
                uv_filter: subIngredient.uv_filter || "",
                colorant: subIngredient.colorant?.toLowerCase() || "",
                iecic_lo: subIngredient.iecic_lo || "",
                iecic_ro: subIngredient.iecic_ro || "",
                gluten: subIngredient.gluten?.toLowerCase() || "",
                cramer_class: subIngredient.cramer_class || "",
                cas_number: subIngredient.cas_number || "",
                unique_smiles: data.unique_smiles || "",
                skin_sensitizer_comment:
                    subIngredient?.skin_sensitizer_comment || "",
                food_use_comment: subIngredient?.food_use_comment || "",
                gluten_comment: subIngredient?.gluten_comment || "",
                colorant_comment: subIngredient?.colorant_comment || "",
                uv_filter_comment: subIngredient?.uv_filter_comment || "",
                source: subIngredient?.source || "",
            });
        }
    }, [subIngredient]);

    const ingredientSource = useMemo(
        () => (data?.source ? InciSource[data.source] : InciSource.cosIng),
        [data?.source],
    );

    return { data, setData, subIngredient, ingredientSource };
};
